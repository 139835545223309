
	import tip from '@/components/tip'
	export default {
		name: 'App',
		components: {
			tip
		},
		methods: {
			onSetColor() {
				window.Excel.run(async context => {
					const range = context.workbook.getSelectedRange();
					range.format.fill.color = 'green';
					await context.sync();
				});
			}
		}
	};
