import {
	createI18n
} from 'vue-i18n'


import zh from '@/lang/zh.json'
import en from '@/lang/en.json'
import tr from '@/lang/tr.json'

const i18n = createI18n({
	messages: {
		zh,
		en,
		tr
	},
	locale: localStorage.getItem('language') || 'zh',
	globalInjection: true,
	legacy: false,
})

export default i18n